import { useEffect, useState } from "react"
import Image from "next/image"
import { Box, Heading, useBreakpointValue } from "@chakra-ui/react"
import { imageLoader } from "@utils/imageLoader"
import { BlockCoverImage } from "../../typings"

interface CoverImageProps {
  block: BlockCoverImage
}

const CoverImage: React.FC<CoverImageProps> = ({ block }) => {
  const [isMounted, setIsMounted] = useState(false)
  const coverHeight = useBreakpointValue({
    base: "200px",
    md: "300px",
    lg: "450px",
    xl: "720px",
  })

  useEffect(() => {
    setIsMounted(true)
  }, [])

  if (!isMounted) {
    return null
  }

  return (
    <>
      <Box
        mb={12}
        position="relative"
        width="100%"
        height={coverHeight}
        overflow="hidden"
        borderRadius="lg"
        boxShadow="0 4px 12px rgba(0, 0, 0, 0.3)"
      >
        <Image
          loader={imageLoader}
          src={block.image.url}
          alt={block.heading || "Cover image"}
          layout="fill"
          objectFit="contain"
          objectPosition="center"
          sizes="(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 1920px"
          priority
          quality={85}
          style={{ borderRadius: "inherit" }}
        />
      </Box>
      <Box
        textAlign="center"
        width="100%"
        py={4}
        maxW="45rem"
        mx="auto"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Heading variant="contentHeading2">{block.heading}</Heading>
      </Box>
    </>
  )
}

export default CoverImage
