import { Box, Grid, GridItem } from "@chakra-ui/react"
import { BlockTalentGrid } from "@typings/index"
import TalentCard from "./TalentCard"

const TalentCarousel = (props: { block: BlockTalentGrid }): JSX.Element => {
  const { talents } = props.block

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        mt={[6, 7, 16]}
        mb="2"
      ></Box>

      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        {talents.map((talent, index) => (
          <GridItem key={index} w="100%">
            <TalentCard talent={talent} />
          </GridItem>
        ))}
      </Grid>
    </>
  )
}

export default TalentCarousel
