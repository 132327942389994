import { Box, Heading } from "@chakra-ui/react"
import { BlockSteps } from "@typings/index"
import Carousel from "./Carousel"
import CarouselControls from "./CarouselControls"

const Steps = ({ block }: { block: BlockSteps }): JSX.Element => {
  const { id, heading, steps } = block

  return (
    <Box width="100%" mt={[6, 7, 16]}>
      {heading && (
        <Box width="100%" textAlign="center">
          <Heading mb="4">{heading}</Heading>
        </Box>
      )}
      <Box display="flex" flexDirection="row-reverse" mb={[0, 0, 0, 4]}>
        <CarouselControls id={id} />
      </Box>
      <Carousel id={id} items={steps} />
    </Box>
  )
}

export default Steps
