import Image from "next/image"
import Link from "next/link"
import { Box, Heading, useBreakpointValue } from "@chakra-ui/react"
import { TalentPage } from "@typings/index"
import { imageLoader } from "@utils/imageLoader"

const TalentCard = ({ talent }: { talent: TalentPage }): JSX.Element => {
  const { title, thumbnail, slug } = talent
  const isSmallScreen = useBreakpointValue({ base: true, md: false })

  return (
    <Link href={`/${slug}`} passHref>
      <Box as="a" display="block">
        <Box
          position="relative"
          paddingTop="100%"
          backgroundColor="#222"
          borderRadius="lg"
          overflow="hidden"
          cursor="pointer"
          sx={{
            h2: {
              transition: "transform 0.3s ease-out",
            },
            img: {
              transition: "transform 0.35s ease-out",
            },
            _hover: {
              h2: {
                transform: `translateY(-5px)`,
              },
              img: {
                transition: "transform 0.35s ease-out",
                transform: `scale(1.05)`,
              },
            },
          }}
        >
          <Image
            loader={imageLoader}
            src={thumbnail?.url || thumbnail.url}
            objectFit="cover"
            layout="fill"
            alt={title}
          />
          {!isSmallScreen && (
            <Box
              position="absolute"
              top="0"
              left="0"
              w="100%"
              h="100%"
              bgGradient="linear(to-b, rgba(30, 30, 35, 0), rgba(30, 30, 35, 0.6))"
            />
          )}
          {!isSmallScreen && (
            <Box
              position="absolute"
              left="4"
              bottom="4"
              width={[`calc(100% - 2rem)`]}
            >
              <Heading variant="contentHeading2" color="white">
                {title}
              </Heading>
            </Box>
          )}
        </Box>
        {isSmallScreen && (
          <Box mt={2} textAlign="center">
            <Heading color="white" fontSize={{ base: "md", sm: "lg" }}>
              {title}
            </Heading>
          </Box>
        )}
      </Box>
    </Link>
  )
}

export default TalentCard
