import Head from "next/head"
import { useRouter } from "next/router"
import { useTranslations } from "next-intl"
import { Show } from "typings"
import { siteUrl } from "@utils/helpers"
import { getPriceData } from "@utils/pricing"

const organisation = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "Chefslive Oy",
  url: siteUrl,
  logo: `${siteUrl}/images/logo.png`,
}

export const ShowSEO = ({ show }: { show: Show }): JSX.Element => {
  const router = useRouter()

  const {
    title,
    coverImage,
    showStartTime,
    showEndTime,
    coverImageThumbnail,
    talent,
  } = show

  const { price, currencyCode } = getPriceData(show.price)

  const currentUrl = `${siteUrl}${router.asPath}`

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Event",
    name: title,
    startDate: showStartTime,
    endDate: showEndTime,
    eventStatus: "https://schema.org/EventScheduled",
    eventAttendanceMode: "https://schema.org/OnlineEventAttendanceMode",
    location: {
      "@type": "VirtualLocation",
      url: `${siteUrl}${router.asPath}`,
    },
    image: [
      coverImageThumbnail?.url ?? `${coverImage?.url}?fit=fill&w=800&h=800`,
      `${coverImage?.url}?fit=fill&w=1200&h=900`,
      `${coverImage?.url}?fit=fill&w=1200&h=630`,
    ],
    // description: "", TODO
    offers: {
      "@type": "Offer",
      url: currentUrl,
      price: String(price / 100),
      priceCurrency: currencyCode,
      availability: "https://schema.org/InStock",
    },
    organizer: organisation,
    performer: talent
      ? {
          "@type": "Person",
          name: talent?.[0].name,
          image: talent?.[0].profileImage.url,
        }
      : null,
  }

  return (
    <Head>
      <title>{title} – Chefslive</title>
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content="Chefslive" />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        content={`https:${coverImage?.url}?fit=fill&w=1200&h=630`}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta
        name="twitter:image"
        content={`https:${coverImage?.url}?fit=fill&w=1200&h=630`}
      />
      <link rel="canonical" href={currentUrl} />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(structuredData, null, 2),
        }}
      />
    </Head>
  )
}

type PageSEOProps = {
  title?: string
  description?: string
}

export const PageSEO = ({ title, description }: PageSEOProps): JSX.Element => {
  const router = useRouter()
  const t = useTranslations("frontPage")

  return (
    <Head>
      <title>{title || t("pageTitle")} - Chefslive</title>
      <meta
        name="description"
        content={description || String(t("pageDescription"))}
      />
      <meta property="og:title" content={`${title} - Chefslive`} />
      <meta property="og:site_name" content="Chefslive" />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        content={`${siteUrl}/images/default-share-image.png`}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta
        name="twitter:image"
        content={`${siteUrl}/images/default-share-image.png`}
      />
      <link rel="canonical" href={`${siteUrl}${router.asPath}`} />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({ organisation }, null, 2),
        }}
      />
    </Head>
  )
}

export const TextPageSEO = (): JSX.Element => {
  return <div>page</div>
}
