import { GetServerSideProps } from "next"
import { Box } from "@chakra-ui/layout"
import { PageSEO } from "@components/HeadSEO"
import CoverImage from "@components/Landing/CoverImage"
import FeaturedCarousel from "@components/Landing/FeaturedCarousel"
import Promo from "@components/Landing/Promo"
import Steps from "@components/Landing/Steps"
import TalentGrid from "@components/Landing/TalentGrid"
import Notice from "@components/Notice"
import { fetchLandingBySlug, parseLandingPageBlocks } from "@utils/contentful"
import { getUserAccessList } from "@utils/serversideHelpers"
import {
  PageBlocks,
  BlockCoverImage,
  BlockCarousel,
  BlockTalentGrid,
  BlockSteps,
  BlockPromo,
} from "../typings"

export const BlockPicker = (props: { block: PageBlocks }): JSX.Element => {
  const { block } = props

  switch (block.type) {
    case "blockTalentGrid":
      return <TalentGrid block={block as BlockTalentGrid} />
    case "blockCoverImage":
      return <CoverImage block={block as BlockCoverImage} />
    case "blockCarousel":
      return <FeaturedCarousel block={block as BlockCarousel} />
    case "blockSteps":
      return <Steps block={block as BlockSteps} />
    case "blockPromo":
      return <Promo block={block as BlockPromo} />
    default:
      return <></>
  }
}

export const getServerSideProps: GetServerSideProps = async ({ req }) => {
  const landingPageData = await fetchLandingBySlug("etusivu")
  if (landingPageData instanceof Error || !landingPageData) {
    return {
      notFound: true,
    }
  }

  const accessList = await getUserAccessList(req)

  const blocks = landingPageData.fields.content
    .filter((item) => item.fields) // Blocks should never arrive here empty. Check just in case.
    .map((block) => parseLandingPageBlocks(block, accessList))

  return {
    props: { blocks },
  }
}

type IndexPageProps = {
  blocks: PageBlocks[]
}

const Index = (props: IndexPageProps): JSX.Element => {
  const { blocks } = props

  return (
    <>
      <PageSEO />
      <Box>
        <Notice />
        {blocks.map((block) => (
          <BlockPicker key={block.id} block={block} />
        ))}
      </Box>
    </>
  )
}

export default Index
