import { Box, Heading } from "@chakra-ui/react"
import { BlockCarousel } from "@typings/index"
import Carousel from "./Carousel"
import CarouselControls from "./CarouselControls"

const FeaturedCarousel = (props: { block: BlockCarousel }): JSX.Element => {
  const { id, shows, heading } = props.block

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        mt={[6, 7, 16]}
        mb="2"
      >
        <Heading variant="subHeading1">{heading ? heading : " "}</Heading>
        <CarouselControls id={id} />
      </Box>

      <Carousel id={id} items={shows} />
    </>
  )
}

export default FeaturedCarousel
