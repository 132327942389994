import NextLink from "next/link"
import { Flex, Link } from "@chakra-ui/react"
import { useTranslations } from "use-intl"
import { useUser } from "@contexts/userContext"
import AlertIcon from "./Icons/Alert"

const Notice = (): JSX.Element => {
  const { user } = useUser()
  const t = useTranslations()

  if (!user)
    return (
      <Flex px="4" pt={[4]} pb={[4, null, null, 6]} justifyContent="center">
        <AlertIcon sx={{ mr: 4, color: "orange" }} />{" "}
        <span>
          {t("anonymousUsersNotice.noticeMsg")}{" "}
          <NextLink href={String(t("navigation.signUpUrl"))} passHref>
            <Link>{t("anonymousUsersNotice.signUpLabel")}</Link>
          </NextLink>
        </span>
      </Flex>
    )

  return <></>
}

export default Notice
