import Link from "next/link"
import { Box, Heading, Text, Button } from "@chakra-ui/react"
import { BlockPromo } from "../../typings"

type BlockPromoProps = {
  block: BlockPromo
}

const Promo = (props: BlockPromoProps): JSX.Element => {
  const {
    heading,
    description,
    link: { label, url, internal },
  } = props.block

  return (
    <Box
      width="100%"
      mt={[6, 7, 16]}
      py={["10", "14"]}
      px="4"
      textAlign="center"
      bgGradient="linear(to-b, gray.100, black)"
      borderRadius="lg"
    >
      <Heading>{heading}</Heading>
      {description && (
        <Text mt={[2, null, 4]} maxW="600" ml="auto" mr="auto">
          {description}
        </Text>
      )}
      {internal ? (
        <Link href={url} passHref>
          <Button as="a" mt="6">
            {label}
          </Button>
        </Link>
      ) : (
        <Button
          as="a"
          href={url}
          rel="noreferrer noopener"
          target="_blank"
          mt="6"
        >
          {label}
        </Button>
      )}
    </Box>
  )
}

export default Promo
